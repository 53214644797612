#main-img {
    height: 50px;
    width: 146px;
    
    &.dark {
        background-image: url(./img/logo-dark.png);
        width: 150px;
        background-repeat: no-repeat;
      }

    &.light {
        background-image: url(./img/logo-light.png);
        width: 150px;
        background-repeat: no-repeat;
      }
  } 
  
  @media screen and (max-width: 767px) {   
      #main-img{
          background-image: url(./img/logo-dark.png);
      } 
  }
  
  @media screen and (min-width: 768px) {      
      #main-img{      
          background-image: url(./img/logo-dark.png);
      } 
  } 
  @media (min-width: 992px) {      
        #main-img{        
            background-image: url(./img/logo-light.png);
            width: 150px;
        } 
  }
  @media (min-width: 1200px) {    
       #main-img{         
           background-image: url(./img/logo-light.png);
           width: 150px;
       } 
  }